import React from 'react'
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { Navigation, Pagination, Scrollbar, A11y, EffectFade, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import axios from 'axios';
import { useEffect, useState } from 'react';


function Portfolio() {

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
 
    function performSignIn() {
      let headers = new Headers();
      let url = "/api/categories";
      headers.append('Content-Type', 'application/json');
      headers.append('Accept', 'application/json');
      headers.append('Origin', 'http://localhost:3000');

      fetch(url, {
        mode: 'cors',
        credentials: 'include',
        method: 'GET',
        cache: 'no-cache',
        headers: headers
      })
        .then(response => response.json())
        .then(json => console.log(json))
        .catch(error => console.log('Authorization failed: ' + error.message));
    }


    performSignIn()

    // Fetch data using axios
    axios.get('/backend/api/categories')

      .then(response => {
        
        setData(response.data); // Set data from API response
        setLoading(false);      // Set loading to false when data is fetched
      })
      .catch(err => {
        setError(err.message);  // Set error message if request fails
        setLoading(false);
      });
  }, []);


  if (loading) {
    return <p>Loading categories...</p>;
  }

  return (
    <div>

      <div className="tmp-product-area tmp-section-gapTop bg-product-1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-head color-white">
                <div className="section-sub-title center-title">
                  <img
                    src="assets/images/services/section-custom-menubar.png "
                    alt="Corporate_service"
                  />
                  <span className="subtitle">JOBS CATAGORY</span>
                </div>
                <h2 className="title split-collab">Let's Check Your Best Works </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="container-full">
          <div className="row">
            <div className="col-lg-12">
              {/* product swiper area start */}
              <div className="product-swiper-area-one">
                <Swiper
                  // install Swiper modules
                  modules={[Navigation, Pagination, EffectFade, Scrollbar, A11y, Autoplay]}
                  className="mySwiper-portfolio-1"
                  speed={500}
                  slidesPerView={5}
                  spaceBetween={30}
                  loop={true}
                  centeredSlides={true}
                  autoplay={{
                    delay: 5500,
                    disableOnInteraction: false,
                  }}
                  navigation={{
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                  }}
                  breakpoints={{
                    1200: { slidesPerView: 5 },
                    900: { slidesPerView: 3 },
                    768: { slidesPerView: 3 },
                    580: { slidesPerView: 2 },
                    450: { slidesPerView: 1 },
                    0: { slidesPerView: 1 },
                  }}
                >

                  {data.length > 0 ? (
                    data.map(item => (
                  <SwiperSlide>
                    <div
                      className="signle-product-start"
                      data-tmp-cursor="lg text-secondary transparent fw-bold"
                      data-tmp-cursor-icon="fa-solid fa-arrow-up-right"
                    >
                      <Link to={'ProjectDetails'} className="thumbnail">
                        <img
                          src={item.image_url}
                          alt={item.name}
                        />
                      </Link>
 

                      <div className="inner-content">
                        <Link to={'ProjectDetails'}>
                          <h5 className="title">{item.name}</h5>
                          <i className='{item.icon}'></i>
                        </Link>
                        <span className="designation">{item.slug}</span>
                        <div className="tag" />
                      </div>


                    </div>
                  </SwiperSlide>
                  ))
                  ) : (
                    <p>No categories available.</p>
                  )}

 
                  <div className="swiper-button-next"></div>
                  <div className="swiper-button-prev"></div>
                  <div className="swiper-pagination"></div>
                </Swiper>

              </div>
              {/* product swiper area end */}
            </div>
          </div>
        </div>
      </div>


    </div>
  )
}

export default Portfolio