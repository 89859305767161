import React from 'react'

function Breadcrumb(props) {
    return (
        <div>



            {/* Start Breadcrumb area  */}
            <div className="breadcrumb-area bg_image tmp-section-gap breadcrumb-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="breadcrumb-inner text-center">
                                <h1 className="title split-collab">Services</h1>
                                <ul className="page-list">
                                    <li className="tmp-breadcrumb-item">
                                        <a href="/">Home</a>
                                    </li>
                                    <li className="icon">
                                        <i className="fa-solid fa-angle-right" />
                                    </li>
                                    <li className="tmp-breadcrumb-item active">Affordable services that exceed expectations</li>

                                </ul>
                                <p style={{ color:"white" }}>Discover Work Supply’s comprehensive range of workforce solutions tailored to meet your organisation’s unique needs. From temporary agency workers to recruitment services and outsourcing solutions, we offer efficient and reliable options to streamline your operations and enhance productivity. Explore how our services can support your business goals and drive success in today’s competitive market.</p>
                                <div className="circle-1" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Breadcrumb area  */}


        </div>
    )
}

export default Breadcrumb