import React, { useEffect }  from 'react';
import WOW from "wowjs";
import { Link } from 'react-router-dom';

function ServiceOne(){
  useEffect(() => {
    const wow = new WOW.WOW({
      live: false, // This disables continuous scanning for new elements
    });
    wow.init();
  }, []);
  
    return(
      <div>
          <div>
            <div className="tmp-services-area services-style--1 background-image-services bg_image tmp-section-gap">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="section-head">
                      <div className="section-sub-title center-title">
                        <img alt="Business_Consulting_services" src="assets/images/services/section-custom-menubar.png " />
                        <span>
                          OUR SERVICES
                        </span>
                      </div>
                      <h2 className="title split-collab">
                        Our Provided Services
                      </h2>
                    </div>
                  </div>
                </div>
                <div className="row g-5">
                  <div className="col-lg-4 col-md-6 col-sm-12 col-12 wow fadeInLeft" data-wow-duration=".8s" data-wow-delay=".1s">
                    <div className="single-services service-style-one">
                      <div className="services-inner  with-shadow card-bg-color">
                        <div className="thumbnail">
                          <img
                            alt="Business consulting services"
                            src="assets/images/services/services-icon-1.png"
                          />
                        </div>
                        <div className="services-content">
                          <Link to={"/ServiceDetails"}>
                            <h5 className="title">
                            Temporary Staffing
                            </h5>
                          </Link>
                          <p className="desctiption">
                          Our Temporary Staffing service offers flexible and efficient solutions for organisations facing temporary workforce requirements. With a focus on reliability and responsiveness, we facilitate the placement of skilled temporary agency workers to meet specific project needs or seasonal demands. From administrative support to specialised technical roles, our diverse pool of talent ensures that clients receive the right personnel at the right time, allowing for seamless operations and enhanced productivity
                          </p>
                          <Link className="icon angle-roted" to={"/ServiceDetails"}>
                            <i className="fa-solid fa-arrow-right" />
                          </Link>
                        </div>
                        <div className="number">
                          <img alt="Business Consulting number" src="assets/images/services/icon-01.png" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 col-12 wow fadeInLeft" data-wow-duration=".8s" data-wow-delay=".2s">
                    <div className="single-services service-style-one">
                      <div className="services-inner  with-shadow card-bg-color">
                        <div className="thumbnail">
                          <img
                            alt="Business consulting services"
                            src="assets/images/services/services-icon-2.png"
                          />
                        </div>
                        <div className="services-content">
                          <Link to={"/ServiceDetails"}>
                            <h5 className="title">
                            Outsourcing
                            </h5>
                          </Link>
                          <p className="desctiption">
                          Our Outsourcing service provides organisations with the opportunity to delegate non-core tasks to specialised third-party providers. By outsourcing tasks such as administrative support, customer service operations, or technical functions, clients can focus on their core business activities while benefiting from increased efficiency and cost savings. With Work Supply managing the direction, control, and supervision of outsourced tasks, clients can trust in reliable and accountable service delivery, freeing up valuable resources for strategic growth and innovation.
                          </p>
                          <Link className="icon angle-roted" to={"/ServiceDetails"}>
                            <i className="fa-solid fa-arrow-right" />
                          </Link>
                        </div>
                        <div className="number">
                          <img alt="Business Consulting number" src="assets/images/services/icon-02.png"/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 col-12 wow fadeInLeft" data-wow-duration=".8s" data-wow-delay=".3s">
                    <div className="single-services service-style-one">
                      <div className="services-inner  with-shadow card-bg-color">
                        <div className="thumbnail">
                          <img
                            alt="Business consulting services"
                            src="assets/images/services/services-icon-3.png"
                          />
                        </div>
                        <div className="services-content">
                          <Link to={"/ServiceDetails"}>
                            <h5 className="title">
                            International Recruitment
                            </h5>
                          </Link>
                          <p className="desctiption">
                          Our International Recruitment service offers a global approach to talent acquisition, enabling organisations to access top talent from around the world. Leveraging our extensive network and expertise, we source skilled professionals who meet clients' international staffing needs, whether for short-term projects or long-term placements. With a focus on cultural sensitivity, compliance, and seamless integration, we ensure a smooth recruitment process and successful outcomes for both clients and candidates, driving business growth and success on a global scale.
                          </p>
                          <Link className="icon angle-roted" to={"/ServiceDetails"}>
                            <i className="fa-solid fa-arrow-right" />
                          </Link>
                        </div>
                        <div className="number">
                          <img
                            alt="Business Consulting number"
                            src="assets/images/services/icon-03.png"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="services-bottom">
                      <div className="services-bottom-inner">
                        <p className="description">
                          Our success as consultants will depend on the essential rightness of the advice.
                        </p>
                        <p className="description">
                          Want to learn more About us?
                          <button className="btn-simple">
                            Click here
                          </button>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    )
}


export default ServiceOne





