import React from 'react'
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useEffect, useState } from 'react';


function ServiceFIve() {

  // const [data, setData] = useState([]);
  // const [loading, setLoading] = useState(true);
  // const [error, setError] = useState(null);

  // useEffect(() => {
  //   // Fetch data using axios
  //   axios.get('http://127.0.0.1:8000/api/categories', {
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'Accept': 'application/json',
  //       'Origin': 'http://localhost:3000'
  //     }
  //   })
  //   .then(response => {
  //     setData(response.data); // Set data from API response
  //     setLoading(false);      // Set loading to false when data is fetched
  //   })
  //   .catch(err => {
  //     setError(err.message);  // Set error message if request fails
  //     setLoading(false);
  //   });
  // }, []); // Empty dependency array ensures the effect runs once on component mount


    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
  
    useEffect(() => {
   
      function performSignIn() {
        let headers = new Headers();
        let url = "/api/categories";
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('Origin', 'http://localhost:3000');
  
        fetch(url, {
          mode: 'cors',
          credentials: 'include',
          method: 'GET',
          cache: 'no-cache',
          headers: headers
        })
          .then(response => response.json())
          .then(json => console.log(json))
          .catch(error => console.log('Authorization failed: ' + error.message));
      }
  
  
      performSignIn()
  
      // Fetch data using axios
      axios.get('/backend/api/categories')
  
        .then(response => {
          
          setData(response.data); // Set data from API response
          setLoading(false);      // Set loading to false when data is fetched
        })
        .catch(err => {
          setError(err.message);  // Set error message if request fails
          setLoading(false);
        });
    }, []);
  
  
    if (loading) {
      return <p>Loading categories...</p>;
    }


    
  return (
    <div>
        
        <div className="service-area tmp-section-gapBottom">
            <div className="container">
                <div className="row">
                <div
                    className="col-lg-12">
                    <div className="section-head">
                    <div className="section-sub-title center-title">
                        <img
                        src="assets/images/services/section-custom-menubar.png "
                        alt="Business_Consulting_services"
                        />
                        <span>OUR SERVICES</span>
                    </div>
                    <h2 className="title split-collab">Our Provided Services</h2>
                    </div>
                </div>
                </div>
                <div className="row g-3">
                    
                {data.length > 0 ? (
                    data.map(item => (
                <div
                    className="col-lg-3 col-md-4 col-sm-6 col-12 wow fadeInUp p-0 m-0" data-wow-duration=".8s" data-wow-delay=".1s">
                    <div className="service-inner financial-service-inner agency-service">
                    <div className="icon" style={{color:'blue',fontSize:'40px'}}>
                    
                    <i className={`fa ${item.icon}`} aria-hidden="true"></i>
                    </div>
                    <div className="content">
                        <h2 className="title">{item.name}</h2>
                        <p className="description">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod.
                        </p>
                        <div className="three--dot">
                        <div className="dot dot-one" />
                        <div className="dot dot-two" />
                        <div className="dot dot-two" />
                        </div>
                    </div>
                    <Link to={'/JobDetails'} className="over_link"></Link>
                    </div>
                </div>

                ))
                ) : (
                <p>No categories available.</p>
                )}

                </div>
            </div>
        </div>

    </div>
  )
}

export default ServiceFIve