import React, { useState } from "react";
import emailjs from "emailjs-com";
import HeaderInner from '../header/HeaderInner';
import Contact from '../inner/breadcrumb/Contact';
import Footer from '../elements/Footer';
import HeaderFive from "../header/HeaderFive";
import HeaderFour from "../header/HeaderFour";
import HeaderTwo from "../header/HeaderTwo";
import HeaderOne from "../header/HeaderOne";
import HeaderSix from "../header/HeaderSix";





function ContactPage() {
    const [formData, setFormData] = useState({
        name: "",
        phone: "",
        email: "",
        subject: "",
        message: "",
      });
    
      const handleChange = (e) => {
        setFormData({
          ...formData,
          [e.target.name]: e.target.value,
        });
      };
    
      const handleSubmit = (e) => {
        e.preventDefault();
        
        emailjs
          .send(
            "YOUR_SERVICE_ID", // Replace with your EmailJS service ID
            "YOUR_TEMPLATE_ID", // Replace with your EmailJS template ID
            formData,
            "YOUR_USER_ID" // Replace with your EmailJS user ID
          )
          .then(
            (response) => {
              console.log("SUCCESS!", response.status, response.text);
              alert("Message sent successfully!");
            },
            (err) => {
              console.log("FAILED...", err);
              alert("Failed to send message. Try again.");
            }
          );
    
        setFormData({
          name: "",
          phone: "",
          email: "",
          subject: "",
          message: "",
        });
      };
    
  return (
    <div className='service-details contact-page'>
        <div className="">
            <HeaderInner/>
            {/* <HeaderSix/> */}
            {/* <HeaderFive/> */}
            {/* <HeaderFour/> */}
            {/* <HeaderTwo/> */}
            {/* <HeaderThree/> */}
            {/* <HeaderOne/> */}
            <main>
                <Contact/>
                    {/* tmp contact area */}
                    <div className="contact-area tmp-section-gap">
                        <div className="container">
                        <div className="row g-5">
                            <div className="col-lg-5">
                            <div className="contact-inner">
                                <div className="section-head section-head-one-side text-align-left">
                                <span className="title">Contact Us</span>
                                <p className="description">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                    eiusmod tempor incididunt ut labore et dolor magnaaliqua.
                                </p>
                                </div>
                                <ul className="ft-link ft-link-style-three">
                                <li>
                                    <div className="single-contact background-transparent">
                                    <div className="icon">
                                        <i className="fa-light fa-phone" />
                                    </div>
                                    <div className="content">
                                        <span>Call Us</span>
                                        <a className="contact-here" href="tel:+4733378901">
                                        +123 34598768
                                        </a>
                                    </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="single-contact background-transparent">
                                    <div className="icon">
                                        <i className="fa-solid fa-envelope-open-text" />
                                    </div>
                                    <div className="content">
                                        <span>E-mail</span>
                                        <a
                                        className="contact-here"
                                        href="mailto:webmaster@example.com"
                                        >
                                        info@uibundle.com
                                        </a>
                                    </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="single-contact background-transparent">
                                    <div className="icon">
                                        <i className="fa-regular fa-map-location-dot" />
                                    </div>
                                    <div className="content">
                                        <span>Address</span>
                                        <a className="contact-here" href="#">
                                        25/5 Double Street, Texas Houston USA
                                        </a>
                                    </div>
                                    </div>
                                </li>
                                </ul>
                                <div className="tmp-tag-list tag-list-one">
                                <a className="tag-list" href="#">
                                    Facebook
                                </a>
                                <a className="tag-list" href="#">
                                    Twitter
                                </a>
                                <a className="tag-list" href="#">
                                    Linkedin
                                </a>
                                <a className="tag-list" href="#">
                                    Skype
                                </a>
                                <a className="tag-list" href="#">
                                    Pinterest
                                </a>
                                </div>
                            </div>
                            </div>
                            <div className="col-lg-7">
                            <div className="contact-inner">
                                <div className="section-head section-head-one-side text-align-left">
                                <span className="title">GET IN TOUCH</span>
                                <p className="description">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                    eiusmod tempor incididunt ut labore et dolor magnaaliqua.
                                </p>
                                </div>
                                <div className="contact-form style-two">
                                <div id="form-messages" />
                                    <form id="contact-form" onSubmit={handleSubmit}>
                                        <div className="contact-form-wrapper row">
                                            <div className="col-lg-6">
                                            <div className="form-group">
                                                <input
                                                className="input-field"
                                                name="name"
                                                placeholder="Name"
                                                id="contact-name"
                                                type="text"
                                                value={formData.name}
                                                onChange={handleChange}
                                                required
                                                />
                                            </div>
                                            </div>
                                            <div className="col-lg-6">
                                            <div className="form-group">
                                                <input
                                                className="input-field"
                                                name="phone"
                                                placeholder="Phone"
                                                type="text"
                                                value={formData.phone}
                                                onChange={handleChange}
                                                required
                                                />
                                            </div>
                                            </div>
                                            <div className="col-lg-6">
                                            <div className="form-group">
                                                <input
                                                className="input-field"
                                                name="email"
                                                placeholder="Email"
                                                type="email"
                                                value={formData.email}
                                                onChange={handleChange}
                                                required
                                                />
                                            </div>
                                            </div>
                                            <div className="col-lg-6">
                                            <div className="form-group">
                                                <input
                                                className="input-field"
                                                name="subject"
                                                placeholder="Subject"
                                                type="text"
                                                value={formData.subject}
                                                onChange={handleChange}
                                                required
                                                />
                                            </div>
                                            </div>
                                            <div className="col-lg-12">
                                            <div className="form-group">
                                                <textarea
                                                className="input-field"
                                                name="message"
                                                placeholder="How can we help"
                                                value={formData.message}
                                                onChange={handleChange}
                                                required
                                                id="contact-message"
                                                />
                                            </div>
                                            </div>
                                            <div className="col-lg-12">
                                            <div className="form-submit-group">
                                                <button className="tmp-btn btn-primary" type="submit">
                                                Submit Now
                                                </button>
                                            </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    {/* tmp contact area */}
                    <div className="tmp-map-area tmp-sectiongapBottom">
                        <div className="map-area-flotimg-container">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d56516.2859130569!2d85.24819285450387!3d27.709014520769113!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb198a307baabf%3A0xb5137c1bf18db1ea!2sKathmandu%2044600%2C%20Nepal!5e0!3m2!1sen!2sbd!4v1714324837639!5m2!1sen!2sbd"
                            width={600}
                            height={650}
                            style={{ border: 0 }}
                            allowFullScreen=""
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                        />
                        </div>
                    </div>
                <Footer/>
            </main>
        </div>
    </div>
  )
}

export default ContactPage