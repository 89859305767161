import React from 'react'
import HeaderInner from '../header/HeaderInner';
import OurTalent from '../inner/OurTalent';
import ServiceOne from '../elements/ServiceOne';
import WorkingProcessThree from '../elements/WorkingProcessThree';
import Team from '../elements/Team';
import TestimonialsBrand from '../elements/TestimonialsBrand';
import Footer from '../elements/Footer';





function Service() {
  return (

        <div className="">
            <HeaderInner/>
            <main>
                {/* <Breadcrumb/> */}
                <OurTalent/>
                <ServiceOne/>
                <TestimonialsBrand/>
                <Footer/>

            </main>
        </div>
    )
}

export default Service