import React from 'react'
import HeaderInner from '../header/HeaderInner';
import BreadcrumbBlog from '../inner/breadcrumb/BreadcrumbBlog';
import Footer from '../elements/Footer';
import { Link } from 'react-router-dom';




function ServiceDetails() {
  return (
    <div className='service-details'>
        <div className="">
            <HeaderInner/>
            <main>
                <BreadcrumbBlog/>
                    {/* latest blog area start */}
                    <div className="altest-blog-area tmp-section-gap">
                        <div className="container">
                        <div className="row g-5">
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="single-blog blog-style-three">
                                <div className="blog-inner">
                                <div className="thumbnail">
                                    <Link to={'/'}>
                                    <img
                                        src="assets/images/blog/blog-2.png"
                                        alt="Business Consulting Blog"
                                    />
                                    </Link>
                                </div>
                                <div className="blog-content text-center">
                                    <div className="blog-head">
                                    <span className="name">20 JAN 2023</span>
                                    <span className="designation">Consulting</span>
                                    </div>
                                    <div className="blog-body">
                                    <Link to={'/'} className="title-area">
                                        <h4 className="title">
                                        Consulted admitting wooded is power acuteness.
                                        </h4>
                                    </Link>
                                    <Link className="btn-read-more" to={'/BlogDetails'}>
                                        <span className="read-more-text">Read More</span>
                                        <span className="read-more-icon">
                                        <i className="fa-solid fa-arrow-right" />
                                        </span>
                                    </Link>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="single-blog blog-style-three">
                                <div className="blog-inner">
                                <div className="thumbnail">
                                    <Link to={'/'}>
                                    <img
                                        src="assets/images/blog/blog-3.png"
                                        alt="Business Consulting Blog"
                                    />
                                    </Link>
                                </div>
                                <div className="blog-content text-center">
                                    <div className="blog-head">
                                    <span className="name">20 JAN 2023</span>
                                    <span className="designation">Consulting</span>
                                    </div>
                                    <div className="blog-body">
                                    <Link to={'/'} className="title-area">
                                        <h4 className="title">
                                        Corporate Design Portfolio Collection
                                        </h4>
                                    </Link>
                                    <Link className="btn-read-more" to={'/BlogDetails'}>
                                        <span className="read-more-text">Read More</span>
                                        <span className="read-more-icon">
                                        <i className="fa-solid fa-arrow-right" />
                                        </span>
                                    </Link>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="single-blog blog-style-three">
                                <div className="blog-inner">
                                <div className="thumbnail">
                                    <Link to={'/'}>
                                    <img
                                        src="assets/images/blog/blog-1.png"
                                        alt="Business Consulting Blog"
                                    />
                                    </Link>
                                </div>
                                <div className="blog-content text-center">
                                    <div className="blog-head">
                                    <span className="name">20 JAN 2023</span>
                                    <span className="designation">Consulting</span>
                                    </div>
                                    <div className="blog-body">
                                    <Link to={'/'} className="title-area">
                                        <h4 className="title">
                                        Executive Portfolio Theme Compilation
                                        </h4>
                                    </Link>
                                    <Link className="btn-read-more" to={'/BlogDetails'}>
                                        <span className="read-more-text">Read More</span>
                                        <span className="read-more-icon">
                                        <i className="fa-solid fa-arrow-right" />
                                        </span>
                                    </Link>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="single-blog blog-style-three">
                                <div className="blog-inner">
                                <div className="thumbnail">
                                    <Link to={'/'}>
                                    <img
                                        src="assets/images/blog/blog-4.png"
                                        alt="Business Consulting Blog"
                                    />
                                    </Link>
                                </div>
                                <div className="blog-content text-center">
                                    <div className="blog-head">
                                    <span className="name">20 JAN 2023</span>
                                    <span className="designation">Consulting</span>
                                    </div>
                                    <div className="blog-body">
                                    <Link to={'/'} className="title-area">
                                        <h4 className="title">
                                        Professional Template Portfolio Selection
                                        </h4>
                                    </Link>
                                    <Link className="btn-read-more" to={'/BlogDetails'}>
                                        <span className="read-more-text">Read More</span>
                                        <span className="read-more-icon">
                                        <i className="fa-solid fa-arrow-right" />
                                        </span>
                                    </Link>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="single-blog blog-style-three">
                                <div className="blog-inner">
                                <div className="thumbnail">
                                    <Link to={'/'}>
                                    <img
                                        src="assets/images/blog/blog-5.png"
                                        alt="Business Consulting Blog"
                                    />
                                    </Link>
                                </div>
                                <div className="blog-content text-center">
                                    <div className="blog-head">
                                    <span className="name">20 JAN 2023</span>
                                    <span className="designation">Consulting</span>
                                    </div>
                                    <div className="blog-body">
                                    <Link to={'/'} className="title-area">
                                        <h4 className="title">
                                        Executive Branding Portfolio Compilation
                                        </h4>
                                    </Link>
                                    <Link className="btn-read-more" to={'/BlogDetails'}>
                                        <span className="read-more-text">Read More</span>
                                        <span className="read-more-icon">
                                        <i className="fa-solid fa-arrow-right" />
                                        </span>
                                    </Link>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="single-blog blog-style-three">
                                <div className="blog-inner">
                                <div className="thumbnail">
                                    <Link to={'/'}>
                                    <img
                                        src="assets/images/blog/blog-6.png"
                                        alt="Business Consulting Blog"
                                    />
                                    </Link>
                                </div>
                                <div className="blog-content text-center">
                                    <div className="blog-head">
                                    <span className="name">20 JAN 2023</span>
                                    <span className="designation">Consulting</span>
                                    </div>
                                    <div className="blog-body">
                                    <Link to={'/'} className="title-area">
                                        <h4 className="title">
                                        Professional Theme Portfolio Compilation
                                        </h4>
                                    </Link>
                                    <Link className="btn-read-more" to={'/BlogDetails'}>
                                        <span className="read-more-text">Read More</span>
                                        <span className="read-more-icon">
                                        <i className="fa-solid fa-arrow-right" />
                                        </span>
                                    </Link>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div className="tmp-pagination-area-next-pev mt--50">
                            <button>
                                <i className="fa-regular fa-chevron-left" />
                            </button>
                            <button>1</button>
                            <button>2</button>
                            <button>3</button>
                            <button>
                                <i className="fa-sharp fa-regular fa-chevron-right" />
                            </button>
                            </div>
                        </div>
                        </div>
                    </div>
                    {/* latest blog area end */}
                <Footer/>
            </main>
        </div>
    </div>
  )
}

export default ServiceDetails